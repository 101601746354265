import React from "react";
import { Link } from "gatsby";
import Layout from "~/components/layout/Layout";

function NotFoundPage() {
  return (
    <Layout>
      <section className="section-pad-lg">
        <div className="container">
          <div className="row">
            <h1>Page not found (404 error)</h1>
            <div className="line-yellow small-line-margin" />
            <div className="lead section-lead-text">
              <p>
                The page you are looking for does not exist or has been moved.
              </p>
              <p>
                If you have any questions please{" "}
                <Link to="/contact/">contact us</Link> or continue browsing from
                the PT Blink <Link to="/">home page</Link>.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default NotFoundPage;
